import { Component, OnInit,HostListener } from '@angular/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }


  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    let element = document.getElementById('new-nav');
    console.log(window.pageYOffset);
    if (window.pageYOffset > 100) {
      element.classList.add('visible')
    } else {
      element.classList.remove('visible')
    }
  }

  openNav() {
    document.getElementById("mySidenav").style.width = "100%";
  }
  closeNav() {
    document.getElementById("mySidenav").style.width = "0";
  }

}
