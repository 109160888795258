import { Component, OnInit,HostListener } from '@angular/core';
declare var $:any;


@Component({
  selector: 'app-family',
  templateUrl: './family.component.html',
  styleUrls: ['./family.component.css']
})
export class FamilyComponent implements OnInit {

  constructor() { }

  ngOnInit() {


    
  }

  
  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    let element = document.getElementById('new-nav');
  
    if (window.pageYOffset > 100) {
      element.classList.add('visible')
    } else {
      element.classList.remove('visible')
    }
  }

  openNav() {
    document.getElementById("mySidenav").style.width = "100%";
  }
  closeNav() {
    document.getElementById("mySidenav").style.width = "0";
  }

}
