import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiServiceService {

  // private baseUrl = "/api/";
private baseUrl = "https://waveexpressllc.com/";

  private addreqUrl = this.baseUrl + "sendmail.php";



  
  private httpHeaders = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('Access-Control-Allow-Origin', '*')
    .set("Access-Control-Allow-Methods", "GET,HEAD,OPTIONS,POST,PUT")
    .set("Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type, Accept, Authorization")

  private options = {
    headers: this.httpHeaders
  };  


  constructor(private _http:HttpClient) { }

  addRequest(data){
    return this._http.post(this.addreqUrl, data, this.options);
  }



 


}
