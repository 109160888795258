import { Component, OnInit,HostListener } from '@angular/core';
declare var $:any;

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.css']
})
export class ClientsComponent implements OnInit {

  constructor() { }

  ngOnInit() {

    $('.secText1').hide();
    $('.secText2').hide();
    $('.secText3').hide();



    $(".sect1").hover(function () {
      $(".caption-text").css("font-size", "35px");
      $(".caption-text").css("transition", "all 0.5s linear");
      $('.secText1').show();
    }, function () {
      $(".caption-text").css("font-size", "20px");
      $('.secText1').hide();
    });


    $(".sect2").hover(function () {
      $(".caption-text2").css("font-size", "35px");
      $(".caption-text2").css("transition", "all 0.5s linear");
      $('.secText2').show();
    }, function () {
      $(".caption-text2").css("font-size", "20px");
      $('.secText2').hide();
    });
    

    $(".sect3").hover(function () {
      $(".caption-text3").css("font-size", "35px");
      $(".caption-text3").css("transition", "all 0.5s linear");
      $(".caption-div1").css("padding-top","20%")
      $('.secText3').show();
    }, function () {
      $(".caption-text3").css("font-size", "20px");
      $('.secText3').hide();
      $(".caption-div1").css("padding-top","50%")
    });
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    let element = document.getElementById('new-nav');
    console.log(window.pageYOffset);
    if (window.pageYOffset > 100) {
      element.classList.add('visible')
    } else {
      element.classList.remove('visible')
    }
  }

  openNav() {
    document.getElementById("mySidenav").style.width = "100%";
  }
  closeNav() {
    document.getElementById("mySidenav").style.width = "0";
  }

}
