import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { DriversComponent } from './drivers/drivers.component';
import { ClientsComponent } from './clients/clients.component';
import { FamilyComponent } from './family/family.component';
import { ApplyComponent } from './apply/apply.component';
import { ContactComponent } from './contact/contact.component';

const routes: Routes = [
  { path: "home", component: HomeComponent },
  { path: "drivers", component: DriversComponent },
  { path: "clients", component: ClientsComponent },
  { path: "family", component: FamilyComponent },
  { path: "apply", component: ApplyComponent },
  { path: "contact", component: ContactComponent },
  { path: "", redirectTo: "/home", pathMatch: "full" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
