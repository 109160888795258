import { Component, OnInit, HostListener } from '@angular/core';

declare var $: any;
@Component({
  selector: 'app-drivers',
  templateUrl: './drivers.component.html',
  styleUrls: ['./drivers.component.css']
})
export class DriversComponent implements OnInit {

  constructor() { }

  ngOnInit() {



  }



  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    let element = document.getElementById('new-nav');
    if (window.pageYOffset > 100) {
      element.classList.add('visible')
    } else {
      element.classList.remove('visible')
    }
  }

  @HostListener('window:load', ['$event'])
  onResize(event) {
    let innerWidth = window.innerWidth;
    console.log(innerWidth)
    if (innerWidth > 768) {

      $('.secText1').hide();
      $('.secText2').hide();
      $('.secText3').hide();
      $('.secText4').hide();
      $('.secText5').hide();
      $('.secText6').hide();


      $(".sect1").hover(function () {
        $(".caption-text").css("font-size", "35px");
        $(".caption-text").css("transition", "all 0.5s linear");
        $('.secText1').show();
      }, function () {
        $(".caption-text").css("font-size", "20px");
        $('.secText1').hide();
      });


      $(".sect2").hover(function () {
        $(".caption-text2").css("font-size", "35px");
        $(".caption-text2").css("transition", "all 0.5s linear");
        $('.secText2').show();
      }, function () {
        $(".caption-text2").css("font-size", "20px");
        $('.secText2').hide();
      });


      $(".sect3").hover(function () {
        $(".caption-text3").css("font-size", "35px");
        $(".caption-text3").css("transition", "all 0.5s linear");
        $('.secText3').show();
      }, function () {
        $(".caption-text3").css("font-size", "20px");
        $('.secText3').hide();
      });


      $(".sect4").hover(function () {
        $(".caption-text4").css("font-size", "35px");
        $(".caption-text4").css("transition", "all 0.5s linear");
        $('.secText4').show();
      }, function () {
        $(".caption-text4").css("font-size", "20px");
        $('.secText4').hide();
      });

      $(".sect5").hover(function () {
        $(".caption-text5").css("font-size", "35px");
        $(".caption-text5").css("transition", "all 0.5s linear");
        $('.secText5').show();
      }, function () {
        $(".caption-text5").css("font-size", "20px");
        $('.secText5').hide();
      });


      $(".sect6").hover(function () {
        $(".caption-text6").css("font-size", "36px");
        $(".caption-text6").css("transition", "all 0.6s linear");
        $('.secText6').show();
      }, function () {
        $(".caption-text6").css("font-size", "20px");
        $('.secText6').hide();
      });

    }else{

      $('.secText1').hide();
      $('.secText2').hide();
      $('.secText3').hide();
      $('.secText4').hide();
      $('.secText5').hide();
      $('.secText6').hide();


    }
  }

  openNav() {
    document.getElementById("mySidenav").style.width = "100%";
  }
  closeNav() {
    document.getElementById("mySidenav").style.width = "0";
  }

}
