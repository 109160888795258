import { Component, OnInit,HostListener } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { toast } from 'angular2-materialize';
import { ApiServiceService } from '../service/api-service.service';
declare var $:any;

@Component({
  selector: 'app-apply',
  templateUrl: './apply.component.html',
  styleUrls: ['./apply.component.css']
})
export class ApplyComponent implements OnInit {

  formData:any;
  loader=false;
  resp:any;

  constructor(private api_service:ApiServiceService,private router: Router) { 

    if(!this.formData){
      this.formData={
        name:'',
        email:'',
        mobile:'',
        license:''
      }
    }
  }

  ngOnInit() {
    $('.loader-spin').hide();
   
  }

  apply(){
    $('.loader-spin').show();
    $('.loader').hide();
 
    var data = JSON.stringify(this.formData);
    this.api_service.addRequest(data).subscribe(res=>{
      this.resp = res;
      if(this.resp === 1 ){
        toast("Data submitted successfully",3000)
        this.router.navigate(['/home'])
      }else{
        toast("Couldn't submit at this time",3000)
        $('.loader-spin').hide();
        $('.loader').show();

      }
    },error=>{
      toast("Please try again later",3000)
      $('.loader-spin').hide();
      $('.loader').show();

    });

  
  }

  
  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    let element = document.getElementById('new-nav');
    if (window.pageYOffset > 100) {
      element.classList.add('visible')
    } else {
      element.classList.remove('visible')
    }
  }

  openNav() {
    document.getElementById("mySidenav").style.width = "100%";
  }
  closeNav() {
    document.getElementById("mySidenav").style.width = "0";
  }

}
