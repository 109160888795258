import { Component, OnInit, HostListener } from '@angular/core';
declare var $: any;


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  secText1 = false;

  constructor() { }

  ngOnInit() {


   

    // $(".sect1").hover(function(){
    //   $(".caption-text").css("font-size", "35px");
    //   $(".caption-text").css("transition", "all 0.5s linear");
    // });
    // $(".caption-text").mouseleave(function(){
    //   $(".caption-text").css("font-size", "20px");
    // });



    // $(".sect2").hover(function(){
    //   $(".caption-text2").css("font-size", "35px");
    //   $(".caption-text2").css("transition", "all 0.5s linear");
    // });
    // $(".caption-text2").mouseleave(function(){
    //   $(".caption-text2").css("font-size", "20px");
    // });


    // $(".sect3").hover(function(){
    //   $(".caption-text3").css("font-size", "35px");
    //   $(".caption-text3").css("transition", "all 0.5s linear");
    // });
    // $(".caption-text3").mouseleave(function(){
    //   $(".caption-text3").css("font-size", "20px");
    // });


    // $(".sect4").hover(function(){
    //   $(".caption-text4").css("font-size", "35px");
    //   $(".caption-text4").css("transition", "all 0.5s linear");
    // });
    // $(".caption-text4").mouseleave(function(){
    //   $(".caption-text4").css("font-size", "20px");
    // });


  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    let element = document.getElementById('new-nav');
    if (window.pageYOffset > 100) {
      element.classList.add('visible')
      $('.truckimg').addClass('animated slideInLeft');
      $('.head1').addClass('animated slideInRight');
      $('.data').addClass('animated slideInRight');
      
      

      $('.head2').addClass('animated slideInLeft');
      $('.data1').addClass('animated slideInLeft');
      $('.link-ap').addClass('animated slideInLeft');
      
      $('.truckkk').addClass('animated slideInRight');

    }
    else {
      element.classList.remove('visible')
    }
  }

  @HostListener('window:load', ['$event'])
  onResize(event) {
    let innerWidth = window.innerWidth;
    console.log(innerWidth)
    if (innerWidth > 768) {

      $('.secText1').hide();
      $('.secText2').hide();
      $('.secText3').hide();
      $('.secText4').hide();
  
  
      $(".sect1").hover(function () {
        $(".caption-text").css("font-size", "35px");
        $(".caption-text").css("transition", "all 0.5s linear");
        $('.secText1').show();
      }, function () {
        $(".caption-text").css("font-size", "20px");
        $('.secText1').hide();
      });
  
  
      $(".sect2").hover(function () {
        $(".caption-text2").css("font-size", "35px");
        $(".caption-text2").css("transition", "all 0.5s linear");
        $('.secText2').show();
      }, function () {
        $(".caption-text2").css("font-size", "20px");
        $('.secText2').hide();
      });
  
  
      $(".sect3").hover(function () {
        $(".caption-text3").css("font-size", "35px");
        $(".caption-text3").css("transition", "all 0.5s linear");
        $('.secText3').show();
      }, function () {
        $(".caption-text3").css("font-size", "20px");
        $('.secText3').hide();
      });
  
  
      $(".sect4").hover(function () {
        $(".caption-text4").css("font-size", "35px");
        $(".caption-text4").css("transition", "all 0.5s linear");
        $('.secText4').show();
      }, function () {
        $(".caption-text4").css("font-size", "20px");
        $('.secText4').hide();
      });

    }else{

      
      $('.secText1').hide();
      $('.secText2').hide();
      $('.secText3').hide();
      $('.secText4').hide();

    }
  }



}
